import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts/highcharts.js';
import HighchartsReact from 'highcharts-react-official';
import { handleWebserviceError } from '../Helpers/Functions';
import { withTranslation } from 'react-i18next';
import { GET_CHART_TEMPERATURE, GET_CHART_HUMIDITY } from '../hooks/useAPI/actions';
import useAPI from '../hooks/useAPI';

window.tempInterval = null;
function ChartTemperature({ date, zone_id, t }) {
    const [chartData, setChartData] = useState({ highcharts_temp: {}, highcharts_hum: {}, zone_id });
    const { callAPI, apiResult } = useAPI();
    const { data, type } = apiResult;

    useEffect(() => {
        window.clearInterval(window.tempInterval);
        if (chartData.zone_id !== undefined) {
            callAPI(GET_CHART_TEMPERATURE(chartData.zone_id, `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`));
            callAPI(GET_CHART_HUMIDITY(chartData.zone_id, `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`));
            window.tempInterval = window.setInterval(() => {
                callAPI(GET_CHART_TEMPERATURE(chartData.zone_id, `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`));
                callAPI(GET_CHART_HUMIDITY(chartData.zone_id, `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`));
            }, 300000);
        } else {
            setChartData({ highcharts_temp: {}, highcharts_hum: {}, zone_id });
        }
    }, [chartData.zone_id, date]);

    useEffect(() => {
        if (chartData.zone_id !== zone_id) {
            setChartData({ highcharts_temp: {}, highcharts_hum: {}, zone_id });
        }
    }, [zone_id]);

    useEffect(() => {
        if (data != null && data.status_code !== undefined) {
            handleWebserviceError(data);
        } else if (data != null && data.status_code === undefined) {
            if (type === 'GET_CHART_TEMPERATURE') {
                setChartData({ ...chartData, highcharts_temp: data['highcharts'] });
            } else if (type === 'GET_CHART_HUMIDITY') {
                setChartData({
                    ...chartData,
                    highcharts_hum: {
                        ...data['highcharts'],
                        Humidity: data['highcharts'].Humidity.map(value => Math.round(value * 100))
                    }
                });
            }
        }
    }, [data]);

    if (chartData.zone_id) {
        var chartTemperatura = {
            chart: {
                type: 'area'
            },
            xAxis: {
                categories: chartData.highcharts_temp.date
            },
            title: false,
            yAxis: [
                {
                    title: {
                        text: `${t('common.temperature')} (°C)`
                    }
                },
                {
                    title: {
                        text: null,
                    },
                    opposite: true,
                    allowDecimals: false,
                }
            ],
            plotOptions: {
                line: {
                    dataLabels: {
                        enabled: true
                    },
                    enableMouseTracking: false
                }
            },
            series: [
                {
                    name: t('common.interior'),
                    color: '#00FFCC',
                    data: chartData.highcharts_temp.Temperature,
                    type: 'areaspline',
                    fillColor: {
                        linearGradient: {
                            x1: 0,
                            y1: 0,
                            x2: 0,
                            y2: 1
                        },
                        stops: [
                            [0, 'rgb(179,255,241)'],
                            [1, 'rgba(216,255,247,0)']
                        ]
                    },
                    lineWidth: 4,
                    states: {
                        hover: {
                            lineWidth: 3,
                            marker: {
                                radius: 4,
                            }
                        }
                    },
                    threshold: null,
                    marker: {
                        enabled: false
                    }
                },
                {
                    name: t('common.exterior'),
                    color: '#b3b3b3',
                    data: chartData.highcharts_temp.External_temperature,
                    type: 'areaspline',
                    fillColor: {
                        linearGradient: {
                            x1: 0,
                            y1: 0,
                            x2: 0,
                            y2: 1
                        },
                        stops: [
                            [0, 'rgb(217,217,217)'],
                            [1, 'rgba(245,245,245,0)']
                        ]
                    },
                    lineWidth: 4,
                    states: {
                        hover: {
                            lineWidth: 3,
                            marker: {
                                radius: 4,
                            }
                        }
                    },
                    threshold: null,
                    marker: {
                        enabled: false
                    }
                },
                {
                    name: t('climate.humidity'),
                    color: '#66b2ff',
                    data: chartData.highcharts_hum.Humidity,
                    type: 'line',
                    dashStyle: 'LongDash',
                    yAxis: 1,
                    lineWidth: 2,
                    enableMouseTracking: true,
                    marker: {
                        enabled: false,
                        states: {
                            hover: {
                                enabled: true,
                                fillColor: '#66b2ff',
                                radius: 4
                            }
                        }
                    },
                    states: {
                        hover: {
                            enabled: true,
                            lineWidth: 3,
                            halo: {
                                size: 5,
                                opacity: 0.2,
                                attributes: {
                                    fill: '#66b2ff'
                                }
                            }
                        }
                    },
                    visible: false,
                    dataLabels: {
                        enabled: false
                    },
                    fillColor: {
                        linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                        stops: [
                            [0, 'rgba(102, 178, 255, 0.3)'],
                            [1, 'rgba(102, 178, 255, 0)']
                        ]
                    },
                    events: {
                        show: function () {
                            this.chart.yAxis[1].update({
                                title: {
                                    text: `${t('climate.humidity')} (%)`
                                }
                            });
                        },
                        hide: function () {
                            this.chart.yAxis[1].update({
                                title: {
                                    text: null
                                }
                            });
                        }
                    }
                }
            ]
        };

        if (chartData.zone_id !== undefined && chartData.highcharts_temp.Temperature !== undefined && chartData.highcharts_temp.Temperature.length > 0 && chartData.highcharts_temp.External_temperature.length > 0) {
            return (
                <div>
                    <div className='chart-container'>
                        <h6 className='chart-title pt-3 ml-3'>{t('climate.temperature_evolution')}</h6>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={chartTemperatura}
                        />
                    </div>
                </div>
            )
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export default withTranslation()(ChartTemperature);
