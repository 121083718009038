import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { withTranslation } from 'react-i18next';
import { GET_SOUND_CHART } from '../hooks/useAPI/actions';
import { handleWebserviceError } from '../Helpers/Functions';
import useAPI from '../hooks/useAPI';

window.soundInterval = null;
const SoundChart = ({ date, zone_id, t }) => {
	const [chartData, setChartData] = useState({ highcharts: undefined, zone_id });
	const { callAPI, apiResult } = useAPI();
	const { data, type } = apiResult;
	const [maxCurrentSound, setMaxCurrentSound] = useState( undefined );

	useEffect(() => {
		if (chartData.zone_id !== undefined) {
			window.clearInterval(window.soundInterval);
			callAPI(GET_SOUND_CHART(chartData.zone_id, `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`));
			window.soundInterval = window.setInterval(() => {
				callAPI(GET_SOUND_CHART(chartData.zone_id, `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`));
			}, 300000);
		} else {
			setChartData({ highcharts: undefined, zone_id });
		}
	}, [chartData.zone_id, date]);

	useEffect(() => {
		if (chartData.zone_id !== zone_id) {
			setChartData({ highcharts: undefined, zone_id });
		}
	}, [zone_id]);

	useEffect(() => {
		if (data != null && data.status_code !== undefined) {
			handleWebserviceError(data);
		} else if (data != null && data.status_code === undefined && type === 'GET_SOUND_CHART') {
			setChartData({ ...chartData, highcharts: data['highcharts'] });
			if (Object.keys(data['highcharts']['Sound']).length > 0 && data['highcharts']['Sound']['max'].length > 0) {
				let maxSoundArray = data['highcharts']['Sound']['max'];
				setMaxCurrentSound(Math.max(...maxSoundArray));
			}
		}
	}, [data]);

	if (chartData.highcharts !== undefined && Object.keys(chartData.highcharts.Sound).length > 0) {
		var chartOptions = {
			chart: {
				type: 'areasplinerange',
				height: 250,
			},
			title: false,
			xAxis: {
				categories: chartData.highcharts.date,
				tickmarkPlacement: 'on',
				title: { enabled: false },
				crosshair: { width: 1, color: 'gray' },
				labels: {
					style: {
						textOverflow: 'none'
					}
				}
			},
			yAxis: {
				title: false,
				max: (maxCurrentSound <= 70) ? Math.pow(1.065, 70 - 40) : Math.pow(1.065, maxCurrentSound - 40),
				min: (maxCurrentSound <= 70) ? -Math.pow(1.065, 70 - 40) : -Math.pow(1.065, maxCurrentSound - 40),
				labels: false,
				tickAmount: 1,
				plotLines: [
					{
						color: '#e6e6e6',
						value: 0
					},
					{
						color: '#ffe4a3',
						width: 2,
						value: Math.pow(1.065, 60 - 40)
					},
					{
						color: '#ffe4a3',
						width: 2,
						value: -Math.pow(1.065, 60 - 40)
					},
					{
						color: '#f8b4a8',
						width: 2,
						value: Math.pow(1.065, 70 - 40)
					},
					{
						color: '#f8b4a8',
						width: 2,
						value: -Math.pow(1.065, 70 - 40)
					}
				]
			},
			tooltip: {
				shared: true,
				useHTML: true,
				borderWidth: 0,
				formatter: function () {
					function getLabel(dbValue) {
						if (dbValue <= 45) return t('sound.quiet');
						if (dbValue <= 60) return t('sound.moderate');
						if (dbValue <= 70) return t('sound.annoying');
						return t('sound.loud');
					}

					const originalMax = this.points[0].point.originalValue;
					const originalAvg = this.points[1].point.originalValue;

					const maxLabel = getLabel(originalMax);
					const avgLabel = getLabel(originalAvg);

					return `
						<table>
							<tr><small>${this.x}h</small></tr><br/>
							<tr>${t('sound.max_sound_value')}: <b>${originalMax} ${t('sound.sound_unit')} (${maxLabel})</b></tr><br/>
							<tr>${t('sound.avg_sound_value')}: <b>${originalAvg} ${t('sound.sound_unit')} (${avgLabel})</b></tr>
						</table>`;
				},
			},
			plotOptions: {
				areasplinerange: {
					lineColor: '#00FFCC',
					fillOpacity: 0.5,
					lineWidth: 2,
					marker: {
						enabled: false,
						states: { hover: { enabled: false } },
					},
					states: { hover: { lineWidthPlus: 0 } },
				},
			},
			series: [
				{
					name: 'Sound Level',
					data: chartData.highcharts.Sound.max.map(point => {
						const expTransformedPoint = (point - 40 <= 0) ? 0 : Math.pow(1.065, point - 40);
						return {
							low: -expTransformedPoint,
							high: expTransformedPoint,
							originalValue: point
						};
					}),
					color: '#007d66',
					fillOpacity: 0,
					lineWidth: 2,
					dashStyle: 'ShortDash',
					marker: { enabled: false },
				},
				{
					name: 'Average Sound Level',
					data: chartData.highcharts.Sound.avg.map(point => {
						const expTransformedPoint = (point - 40 <= 0) ? 0 : Math.pow(1.065, point - 40);
						return {
							low: -expTransformedPoint,
							high: expTransformedPoint,
							originalValue: point
						};
					}),
					color: '#00FFCC',
					fillColor: {
						linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
						stops: [
							[0, 'rgba(0, 255, 204, 0.5)'],
							[0.5, 'rgba(0, 255, 204, 0.1)'],
							[1, 'rgba(0, 255, 204, 0.5)']
						],
					},
				},
			],
			legend: { enabled: false },
		};
	}

	if (chartData.highcharts !== undefined && chartOptions !== undefined && Object.keys(chartData.highcharts.Sound).length > 0) {
		return (
			<div className='chart-container_sound'>
				<h6 className='chart-title pt-3 ml-3'>{t('sound.sound_title')}</h6>
				<HighchartsReact highcharts={Highcharts} options={chartOptions} />
			</div>
		);
	} else {
		return false;
	}
};

export default withTranslation()(SoundChart);
