import React, { useEffect, useState } from 'react';
import {
    Navbar,
    Container,
    NavDropdown,
} from 'react-bootstrap';
import { Link, Route, Switch, useHistory, useParams } from 'react-router-dom';
import { Globals, classList } from './Helpers/Functions';
import Session from './Helpers/Functions';
import Cookie from './Helpers/Cookie';
import './Styles/bootstrap.min.css';
import './Styles/Custom.css';
import Buildings from './Sections/Buildings';
import Zones from './Sections/Zones';
import Modules from './Sections/Modules';
import ZoneDashboard from './Sections/ZoneDashboard';
import PowerDashboard from './Sections/PowerDashboard';
import ZoneSettings from './Sections/ZoneSettings/index';
import DropdownTree from './Components/DropdownTree';
import AlertsPopup from './Components/AlertsPopup';
import Moment from 'react-moment';
import { faCopyright, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Sidebar from './Components/Sidebar';
import { withTranslation } from 'react-i18next';
import useAPI from './hooks/useAPI';
import { LOGIN, WEBPUSH_SAVE_INFORMATION } from './hooks/useAPI/actions';
import Avatar from './Img/icons/default_avatar.svg';
import Logo from './Img/orpheus_opt.png';
import Building from './Sections/Building';
import Notifications from './Sections/Notifications';
import { UtilsContext, ALERTS_TOGGLE } from './Context/utilsContext';
import { Helmet } from 'react-helmet';

function App({ t, i18n }) {
    Globals.token = Cookie.getCookie('token');
    // ! Same key as backend WEBPUSH_SETTINGS -> VAPID_PUBLIC_KEY
    const ApplicationServerKey = "BEYVi-DGgfXdN4AOxDweDK9wQBCJjsAJRJWIIjc4vTskA8DYDORuGaE1bHi8YqytwQjI-1vjUCv4kTbDgY54hNY"
    const [appState, setAppState] = useState({
        isGettingUser: false,
        intro: true,
        logged: false,
        username: '',
        password: '',
        errorMessage: undefined
    });
    const [hasActiveNotifications, setHasActiveNotifications] = useState(false)
    const [currentBuildings, setCurrentBuildings] = useState([])
    const [currentBuildingsChecked, setCurrentBuildingsChecked] = useState([]);
    const [options, setOptions] = useState()
    const history = useHistory();
    const { callAPI, apiResult } = useAPI();
    const { data, type } = apiResult;
    let errorAlert = undefined;

    async function userValidation() {
        let logged = await Session.getUserData();
        setAppState({ ...appState, isGettingUser: false, logged, intro: false, errorMessage: undefined });
    };

    function handleChangeUsername(e) {
        setAppState({ ...appState, username: e.target.value });
    };

    function handleChangePassword(e) {
        setAppState({ ...appState, password: e.target.value });
    };

    function onKeyEnter(e) {
        if (e.keyCode === 13) {
            handleLogin()
        }
    };

    function urlB64ToUint8Array(base64String) {
        const padding = '='.repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding)
            .replace(/-/g, '+')
            .replace(/_/g, '/');

        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);

        for (var i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    }

    function handleLogin() {
        callAPI(LOGIN, { username: appState.username, password: appState.password });
    };

    function handleLogout() {
        if (window.confirm(t('common.confirm_exit'))) {
            Cookie.setCookie('token', '', -1);
            Globals.token = '';
            setAppState({ ...appState, logged: false });
        }
    };

    function postSubscribeObj(statusType, subscription) {
        var browser = navigator.userAgent.match(/(firefox|msie|chrome|safari|trident)/ig)[0].toLowerCase(),
            data = {
                status_type: statusType,
                subscription: subscription.toJSON(),
                browser: browser
            };

        if (appState.logged) {
            callAPI(WEBPUSH_SAVE_INFORMATION, data);
        }
    }

    function initialiseState(reg) {
        if (!(reg.showNotification)) {
            return;
        }

        if (Notification.permission === 'denied') {
            return;
        }

        if (!('PushManager' in window)) {
            return;
        }

        reg.pushManager.getSubscription().then(
            function (subscription) {
                if (subscription) {
                    return subscription;
                }

                setOptions({
                    userVisibleOnly: true,
                    applicationServerKey: urlB64ToUint8Array(ApplicationServerKey)
                })
                reg.pushManager.subscribe(options)
                    .then(
                        function (subscription) {
                            postSubscribeObj('subscribe', subscription);
                        })
            });
    }

    // useEffect(() => {
    //     if ('serviceWorker' in window.navigator) {
    //         window.navigator.serviceWorker.register('webpush/service-worker.js').then(
    //             function (reg) {
    //                 initialiseState(reg);
    //             });
    //     }
    // }, [appState.logged]);

    useEffect(() => {
        userValidation();
    }, []);

    useEffect(() => {
        async function loginCallback() {
            if (data != null && data.status_code !== undefined) {
                setAppState({ ...appState, errorMessage: t('errors.wrong_login') });
            } else if (data != null && data.status_code === undefined) {
                Cookie.setCookie('token', data.token);
                Globals.token = data.token;
                await userValidation();
            }
        }

        if (type === "LOGIN") {
            loginCallback();
        }
    }, [data]);

    function RedirectToBuilding() {
        const { building } = useParams();
        window.location = "https://public.orph.eus/building?building=" + building;
        return null;
    }

    function RedirectToZone() {
        const { zone } = useParams();
        window.location = "https://public.orph.eus/space?id=" + zone;
        return null;
    }

    if (history.location.pathname.startsWith('/public')) {
        return (
            <Switch>
                <Route exact path="/public/buildings/:building" component={RedirectToBuilding} />
                <Route exact path="/public/buildings/:building/zones/:zone" component={RedirectToZone} />
            </Switch>
        );
    } else if (appState.logged && !appState.isGettingUser) {
        return (
            <UtilsContext>
                <Helmet>
                    <script src='https://widget.flowxo.com/embed.js?'
                        data-fxo-widget="eyJ0aGVtZSI6IiM4ZmZlYzkiLCJ3ZWIiOnsiYm90SWQiOiI2MjZmYWNiOTk0YmUyNTAwMjYxMmUwNGYiLCJ0aGVtZSI6IiM4ZmZlYzkifX0="
                        async defer></script>
                </Helmet>
                <div className='wrapper'>
                    <AlertsPopup setHasActiveNotifications={setHasActiveNotifications} />
                    <>
                        <nav className='navbar fixed-top flex-md-nowrap p-0'>
                            <div className="navbar-content">
                                <Navbar.Brand
                                    className='ml-3'
                                    href='/'>
                                    <img
                                        alt='Orpheus'
                                        src={Logo}
                                        className='img-fluid navbar-mai-logo'
                                    />
                                </Navbar.Brand>

                                <DropdownTree
                                    is_filter={false}
                                    currentBuildings={currentBuildings}
                                    currentBuildingsChecked={currentBuildingsChecked}
                                    setCurrentBuildingsChecked={setCurrentBuildingsChecked}
                                    setCurrentBuildings={setCurrentBuildings}
                                />
                                {
                                    (ALERTS_TOGGLE) ? (
                                        <Link
                                            className='navbar-bell navbar-bell-header'
                                            to='/notifications'>
                                            <div className="w3-wrapper">
                                                <div className='ico ico--bell-regular' />
                                                <span className={classList({
                                                    "bell-count": hasActiveNotifications
                                                })}></span>
                                            </div>
                                        </Link>
                                    ) : false
                                }
                            </div>
                            <ul className='navbar-nav px-3'>
                                <NavDropdown
                                    id='basic-nav-dropdown'
                                    data-testid='navUsername'
                                    title={
                                        <>
                                            <img src={Avatar} className='nav-avatar pr-2' alt={Globals.user.username} />
                                            {Globals.user.username}
                                        </>
                                    }>
                                    <NavDropdown.Item
                                        onClick={handleLogout}>{t('common.leave_session')}</NavDropdown.Item>
                                </NavDropdown>
                            </ul>
                        </nav>
                    </>
                    <Sidebar />
                    <div className='content-page'>
                        <Container fluid='true'>
                            <Route exact path='/' component={Buildings} />
                            <Route path='/zones' component={Zones} />
                            <Route path='/modules' component={Modules} />
                            <Route path='/building/:building' component={Building} />
                            <Route path='/energy/:zone_id' component={PowerDashboard} />
                            <Route path='/dashboard/:zone' component={ZoneDashboard} />
                            <Route path='/settings/:zone' component={ZoneSettings} />
                            {
                                (ALERTS_TOGGLE) ? (
                                    <Route path='/notifications' component={Notifications} />
                                ) : false
                            }
                            <div className='col-12 footer-copyright'>
                                <p>
                                    Orpheus <FontAwesomeIcon icon={faCopyright} />{' '}
                                    <Moment format='YYYY'>{new Date()}</Moment>{' '}
                                </p>
                            </div>
                        </Container>
                    </div>
                </div>
            </UtilsContext>
        )
    } else if (!appState.logged && !appState.isGettingUser) {
        if (appState.errorMessage !== undefined) {
            errorAlert = (
                <div
                    data-testid='loginErrorMessage'
                    className='alert alert-danger'>
                    <FontAwesomeIcon icon={faExclamationTriangle} />{' '}
                    {appState.errorMessage}
                </div>
            );
        }

        return (
            (
                <>
                    <nav className='navbar fixed-top flex-md-nowrap p-0'>
                        <Link
                            className='navbar-brand col-sm-3 col-md-2 mr-0'
                            to='/'>
                            <img
                                alt='Orpheus'
                                src={Logo}
                                className='img-fluid navbar-mai-logo'
                            />
                        </Link>
                    </nav>
                    <div className='container-fluid container-login'>
                        <div className='row'>
                            <div className='col-12'>
                                <h1 className='text-center'>{t('common.login')}</h1>
                            </div>
                            <div className='col-12'>
                                <form className='login'>
                                    {errorAlert}
                                    <div className='form-group'>
                                        <label htmlFor='formLoginUsername'>
                                            {t('common.user')}
                                        </label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            id='formLoginUsername'
                                            name='formLoginUsername'
                                            placeholder={t('common.user')}
                                            required=''
                                            onChange={handleChangeUsername}
                                        />
                                    </div>
                                    <div className='form-group'>
                                        <label htmlFor='formLoginPassword'>
                                            {t('common.password')}
                                        </label>
                                        <input
                                            type='password'
                                            className='form-control'
                                            id='formLoginPassword'
                                            name='formLoginPassword'
                                            placeholder={t('common.password')}
                                            required=''
                                            onKeyDown={onKeyEnter}
                                            onChange={handleChangePassword}
                                        />
                                    </div>
                                    <div className='form-group text-center'>
                                        <button
                                            id='formLoginButton'
                                            name='formLoginButton'
                                            type='button'
                                            onClick={handleLogin}
                                            className='btn btn-primary btn-orpheus'>
                                            {t('common.access')}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </>
            )
        )
    } else {
        return false;
    }

};

export default withTranslation()(App);
